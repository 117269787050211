<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>组态管理</span>
        <el-button @click="addConfig" class="btn" type="primary" size="mini" :disabled="configProject.length==0">新增组态</el-button>
      </div>

      <div class="table-tree">
        <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
          <div class="tree-title">
            <div>
              <i class="el-icon-share" style="margin-right:10px;"></i>组态项目
            </div>
            <div>
              <el-button type="primary" size="mini" @click="addConfigProject">新增项目</el-button>
            </div>
          </div>
          <div v-if="configProject.length>0">
            <div class="tree-park" v-for="(item,index) in configProject" :key="index">
              <div class="park-title" :class="current==item.projectId?'bright':''" @click.prevent="configToggle(item.projectId)">
                <span class="class-icon iconfont icon-xiangmu" :style="current==item.projectId?'color:#FFF;':'color:#3498db;'"></span>
                <span>{{item.name}}</span>
                <span class="custom-tree-node">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more-outline operate" :style="current==item.projectId?'color:#FFF;':'color:#3498db;'"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item><div @click="editProject(item)">编辑</div></el-dropdown-item>
                      <el-dropdown-item><div @click="dltProject(item)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </div>
          </div>
          <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
        </div>
        <div style="width:100%;">
          <div class="sub-equipment-model" v-if="tableData.length>0">
            <div class="equipment-item" v-for="(item, index) in tableData" :key="index">
              <div class="equipment-top">
                <div>
                  <span>{{item.name}}</span>
                </div>
                <div></div>
              </div>
              <div class="i-pic">
                <el-image class="i-pic-image" :src="item.coverFileUrl" fit="contain">
                  <div slot="error" class="image-slot">
                    <div class="i-pic-error iconfont icon-beijianleixing-"></div>
                  </div>
                </el-image>
              </div>
              <div class="equipment-bottom">
                <div>
                  <el-button size="mini" type="primary" @click="dataCount(item)">统计</el-button>
                  <el-button size="mini" type="primary" @click="edit(item)">编辑</el-button>
                </div>
                <div>
                  <el-button size="mini" type="danger" @click="dlt(item)">删除</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-empty" v-else>
            暂无数据
          </div>
          <div class="block">
            <el-pagination
              @size-change="sizeChange"
              @current-change="pageChange"
              :current-page="page"
              :page-size="size"
              :page-sizes="[10]"
              background
              :layout="layout"
              :total="total"></el-pagination>
            <!-- :pager-count="count" -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      treeLoading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
 
      roleId: 0,

      configProject: [],
      current: null,
      supplierCode: null,
      equipmentBrandCode: null,
      equipmentName: null,
    };
  },
  computed: {
    layout() {
      let str = "total, sizes, prev, pager, next, jumper";
      this.isSizes?str="sizes,"+str:"";
      return str;
    }
  },
  methods: {
    dayjs,
    // 数据统计
    dataCount(item) {
      this.$router.push({ name: 'iotConfigManageStatistics', params: {row: item.configurationId}})
    },
    // 新增组态项目
    addConfigProject() {
      this.$router.push({ name: 'iotConfigManageConProjectAdd' })
    },
    // 编辑组态项目
    editProject(item) {
      this.$router.push({ name: 'iotConfigManageConProjectEdit', params: {row: item.projectId}})
    },
    dltProject(item) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("configProjectDelete",{
          projectId: item.projectId,
        }).then((res) => {
          this.loadConfigProject();
        });
      });
    },
    // 新增组态
    addConfig() {
      sessionStorage.removeItem('configForm')
      sessionStorage.removeItem('configCellsData')
      sessionStorage.removeItem('canvasURL')
      sessionStorage.setItem('configProjectId', this.current)
      this.$router.push({ name: 'iotConfigManageBaseAdd' })
    },
    // 编辑组态
    edit(item) {
      sessionStorage.removeItem('configForm')
      sessionStorage.removeItem('configCellsData')
      sessionStorage.removeItem('canvasURL')
      sessionStorage.setItem('configProjectId', this.current)
      sessionStorage.setItem('configurationId', item.configurationId)
      this.$router.push({ name: 'iotConfigManageBaseEdit', params: { row: item } })
    },
    // 组态删除
    dlt(item) {
      console.log(item)
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("configurationDelete",{}, {
          configurationId: item.configurationId,
        }).then((res) => {
          this.loadListData();
        });
      });
    },
    // 查询组态
    loadListData() {
      this.loading = true
      this.$ajax.post('configurationQuery', {
        pageNum: this.page,
        pageSize: this.size,
        projectId: this.current,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 切换组态项目
    configToggle(projectId) {
      this.current = projectId
      sessionStorage.setItem('configProjectId', projectId)
      this.loadListData()
    },
    // 获取组态项目
    loadConfigProject() {
      // this.treeLoading = true
      this.$ajax.post('configProjectQuery',{}).then(res => {
        this.configProject = res.data
        if(sessionStorage.getItem('configProjectId')){
          this.current = sessionStorage.getItem('configProjectId')    
          this.loadListData()
        }else{
          this.current = this.configProject[0].projectId
          this.loadListData()
        }
        // this.treeLoading = false
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentCardPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentCardSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadConfigProject()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {

  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.block /deep/{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding: 0 20px;
  .el-pager li {
    margin: 0 5px;
    border-radius: 2px;
  }
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    min-width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
    margin-left: 10px;
  }
}
.tree-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
}
.tree-classify {
  line-height: 35px;
}
.classify-title {
  padding-left: 50px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
}
.sub-equipment-model {
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.sub-empty {
  color: #909399;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
}
.equipment-item {
    width: 316px;
    box-shadow: 0px 0px 20px rgba(211, 211, 211, .5);
    border: 1px solid #ebeef5;
    border-radius: 15px;
    margin-right: 17px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.i-pic {
  margin: 0 auto;
  width: 300px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  .i-pic-image {
    display: block;
    width: 100%;
    height: 100%;
    .i-pic-error {
      font-size: 100px;
      color: #d9ddde;
      padding: 30px 0;
      display: flex;
      justify-content: center;
    }
  }
}
.equipment-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  margin: 10px;
}
.equipment-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    border-top: 1px solid #efefef;
    padding-top: 10px;
    margin: 10px;
}
</style>